import React, { Component, PropTypes } from 'react';
import Hero from '../Hero';
import Highlight from '../Highlight';

export default class HomePage extends Component {
  state = {
    login: false
  }
  copy = (s) => {
    const res = Clipboard.setString(s);
    toast(res ? "Clipboard set" : "Could not set clipboard :(");
  };
  scrollToSignUp = () => {
    this.setState({login: false}, Utils.scrollToSignUp);
  }
  render() {
    const redirect = this.props.location.query.redirect ? `?redirect=${this.props.location.query.redirect}` : "";
    const snippet = ` <form action="https://post.formlyapp.com/your-project-id" method="POST">
    <input type="text" name="name" />
    <input type="email" name="email" />
    <input type="hidden"
        name="config[subject]"
        value="My Website Form"/>
    <input type="submit" value="Send" />
 </form>`
    return (
      <AccountProvider onLogout={this.onLogout} onLogin={this.onLogin}>
        {({isLoading, isSaving, error}, {register, login}) => (
          <div className="homepage">
            <Hero
              title="Formly"
              scrollToSignUp={this.scrollToSignUp}>

              <div className="row hero__content">
                <div className="col-md-5">
                  <div className="card animated fadeIn">
                    <div className="card-header">
                      <p>Posting a form</p>
                    </div>
                    <div className={"hljs-container"}>
                      <Highlight className="html">
                        {snippet}
                      </Highlight>
                      <Button onClick={() => this.copy(snippet)} className={"btn btn-primary hljs-copy"}>
                        Copy
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 arrow-container">
                  <ion className="homepage-icon icon ion-md-arrow-forward"/>
                </div>
                <div className="col-md-6">
                  <img className="img-fluid hero__image" src="/images/email-window@2x.png" />
                </div>
              </div>
            </Hero>

            <div className="feature-container">
              <div className={"text-center col-md-12 col-lg-6 push-lg-3"}>
                <h2>There are different ways to submit a form to Formly</h2>
              </div>
              <div className="container">
                <div className={"homepage-features row mt-5"}>
                  <div className="col-md-3 text-center">
                    <img src="/images/icon-gear.svg" className="homepage-icon" />
                    <p>Change your form action to point to us. You will need to set up a project which only takes a few minutes.</p>
                  </div>
                  <div className="col-md-3">
                    <img src="/images/icon-send.svg" className="homepage-icon" />
                    <p>Submit form data asynchronously in either JSON or as serialised form data.</p>
                  </div>
                  <div className="col-md-3">
                    <img src="/images/icon-filter.svg" className="homepage-icon" />
                    <p>Send additional data with hidden form fields.</p>
                  </div>
                </div>
              </div>
            </div>

            <section className="feature-container feature-container--grey text-center">
              <h2 className="pb-5">Great for static websites</h2>
              <div className="col-lg-4 offset-lg-4">
                <p>Formly simplifies form submission for your website. Set up a form, submit it to us and we'll send you all of the data in an email. No need for setting up a backend server or an email service. We take care of all of that for you. No more worrying about your email server breaking
                </p>
              </div>
              <div className="col-lg-6 offset-lg-3">
                <img className="img-fluid" src="/images/laptop.png"/>
              </div>
            </section>

            {!this.state.login ? (
              <div className="sign-up">
                <div className="signup-form container animated fadeIn col-md-8 col-xl-8">
                  <form id="form" name="form" onSubmit={(e) => {
                    Utils.preventDefault(e);
                    const { email, password, password2 } = this.state;
                    register({ email, password, password2 })
                  }}>

                    <div className="form-intro text-center">
                      <h3>Sign up to get started</h3>
                    </div>
                    {error &&
                      <FormGroup className="col-lg-6 offset-lg-3">
                        <div id="error-alert" className="alert alert-danger">
                          Please check your details and try again
                        </div>
                      </FormGroup>
                    }
                    <fieldset id="details" className="col-lg-6 offset-lg-3">
                      <Input inputProps={{
                        name: "email",
                        className: "full-width",
                        error: error && error.email
                      }}
                        onChange={(e) => {
                          this.setState({ email: Utils.safeParseEventValue(e) })
                        }}
                        className="input-default full-width"
                        placeholder="Email"
                        type="text"
                        name="email" id="email" />
                      {error && error.email ? (<span id="email-error" className="text-danger">{error.email}</span>) : null}
                      <Input
                        inputProps={{
                          name: "password",
                          className: "full-width",
                          error: error && error.password
                        }}
                        placeholder={"Password"}
                        onChange={(e) => {
                          this.setState({ password: Utils.safeParseEventValue(e) })
                        }}
                        className="input-default full-width"
                        type="password"
                        name="password" id="password" />
                      <Input
                        inputProps={{
                          name: "password2",
                          className: "full-width",
                          error: error && error.password2
                        }}
                        placeholder={"Repeat Password"}
                        onChange={(e) => {
                          this.setState({ password2: Utils.safeParseEventValue(e) })
                        }}
                        className="input-default full-width"
                        type="password"
                        name="password2"
                        id="password2"
                      />
                      <div className="form-cta margin-top">
                        <button
                          name="signup-btn"
                          disabled={isLoading || isSaving}
                          className="btn white full-width" type="submit">
                          Sign Up
                                                          </button>
                        <a id="existing-member-btn" onClick={() => this.setState({login: true})}>
                          Already a member?
                        </a>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            ) : (

                <div className="sign-up">
                  <div className="signup-form container animated fadeIn col-md-8 col-xl-8">
                  <form id="form" name="form" onSubmit={(e) => {
                    Utils.preventDefault(e);
                    const { email, password } = this.state;
                    login({ email, password })
                  }}>

                    <div className="form-intro text-center">
                      <h3>Log in</h3>
                    </div>
                    {error &&
                      <FormGroup className="col-lg-6 offset-lg-3">
                        <div id="error-alert" className="alert alert-danger">
                          Please check your details and try again
                        </div>
                      </FormGroup>
                    }
                    <fieldset id="details" className="col-lg-6 offset-lg-3">
                      <Input inputProps={{
                        name: "email",
                        className: "full-width",
                        error: error && error.email
                      }}
                        onChange={(e) => {
                          this.setState({ email: Utils.safeParseEventValue(e) })
                        }}
                        className="input-default full-width"
                        placeholder="Email"
                        type="text"
                        name="email" id="email" />
                      {error && error.email ? (<span id="email-error" className="text-danger">{error.email}</span>) : null}
                      <Input
                        inputProps={{
                          name: "password",
                          className: "full-width",
                          error: error && error.password
                        }}
                        placeholder={"Password"}
                        onChange={(e) => {
                          this.setState({ password: Utils.safeParseEventValue(e) })
                        }}
                        className="input-default full-width"
                        type="password"
                        name="password" id="password" />
                      <div className="form-cta margin-top">
                        <button
                          name="login-btn"
                          disabled={isLoading || isSaving}
                          className="btn white full-width" type="submit">
                          Login
                        </button>
                        <a id="existing-member-btn" onClick={() => this.setState({login: false})}>
                          Don't have an account?
                        </a>
                      </div>
                    </fieldset>
                  </form>
                    </div>
                  </div>

            )}

            <footer className="homepage clearfix">
              <div className="clearfix">
                <div className="brand-footer float-left">
                  <img src="./images/formly-logo-white.svg" alt="Formly" />
                </div>
                <ul className="float-right nav-list">
                  <li><a href="https://docs.formlyapp.com/">Docs</a></li>
                  <li><a href="mailto:formly@solidstategroup.com">Support</a></li>
                </ul>
              </div>
              <div className="built-by text-center">
                <p>Built by <a href="http://www.solidstategroup.com"><img src="./images/ssg-logotype-white-transparent-bg.png" alt="Solid state group" /></a></p>
              </div>
            </footer>
          </div>
        )}
      </AccountProvider>
    )
  }
}
