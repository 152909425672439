module.exports = Object.assign({}, require('./base/_app-actions'), {
	createProject: function (name) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.CREATE_PROJECT,
			name
		});
	},
	getProject: function (id) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.GET_PROJECT,
			id
		});
	},
	selectProject: function (id) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.SELECT_PROJECT,
			id
		});
	},
	editProject: function (config) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.EDIT_PROJECT,
			config
		});
	},
	getResponses: function (startAt, pageSize) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.GET_RESPONSES,
			startAt,
			pageSize
		});
	},
	getPrevResponses: function (pageSize) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.GET_PREV_RESPONSES,
			pageSize
		});
	},
	getNextResponses: function (pageSize) { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.GET_NEXT_RESPONSES,
			pageSize
		});
	},
	deleteProject: function () { //refresh the entire app
		Dispatcher.handleViewAction({
			actionType: Actions.DELETE_PROJECT,
		});
	},
	acceptInvite: function (id) {
		Dispatcher.handleViewAction({
			actionType: Actions.ACCEPT_INVITE,
			id
		});
	},
	deleteInvite: function (id) {
		Dispatcher.handleViewAction({
			actionType: Actions.DELETE_INVITE,
			id
		});
	},
});
