import React, {Component, PropTypes} from 'react';
import Popover from './base/Popover';

const TheComponent = class extends Component {
	displayName: 'TheComponent'

	static contextTypes = {
		router: React.PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	onEditClick = (e) => {
		e.stopPropagation();
		console.log("Stopped prop")
	}

	render() {
		return (
			<AccountProvider>
				{({isLoading, project}) => (
					<Flex className={"aside " + (this.props.className||"")}>
						<div className="brand-container text-center">
							<Link to="/">
								<Row className="app-logo-container">
									<img className="app-logo" src={"/images/formly-logo-white.svg"}/>
								</Row>
							</Link>
						</div>
						{isLoading && <Loader/>}
						{!isLoading && (
							<Flex>
								<Flex className="links">
									<Link
										activeClassName={"active"}
										to={`/project/${this.props.projectId}`
										}><span className={"ion ion-ios-settings green"}/>Form Settings</Link>
									<Link
										id="responses-link"
										activeClassName={"active"}
										to={
											`/project/${this.props.projectId}/responses`
										}><span className={"ion ion-ios-mail red"}/>Form Inbox</Link>
								</Flex>
								<div>
									<Link
										id="project-settings-link"
										activeClassName={"active"}
										to={
											`/project/${this.props.projectId}/settings`
										}><span className={"dot purple"}/>Project Settings</Link>
									<a
										activeClassName={"active"}
										target={"blank"}
										href={`https://docs.formlyapp.com`}>
										Documentation
									</a>
								</div>
							</Flex>
						)}
					</Flex>
				)}
			</AccountProvider>
		);
	}
};

TheComponent.propTypes = {};

module.exports = TheComponent;
