var BaseStore = require('./base/_store');
var data = require('../data/base/_data');

var controller = {
        register: ({email, password, password2}) => {
            store.saving();
            if (password !== password2) {
              store.error = "Passwords do not match";
              store.goneABitWest();
              return;
            }
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then((credentials) => {
                    API.trackEvent(Constants.events.REGISTER);
                    credentials.user.getIdToken().then(token => {
                        data.setToken(token);
                        return controller.onLogin();
                    })
                })
                .catch((e) => API.ajaxHandler(store, e))
        },
        setToken: (token) => {
            store.user = {};
            data.setToken(token);
            return controller.onLogin();
        },
        login: ({email, password}) => {
            store.loading();
            firebase.auth().signInWithEmailAndPassword(email, password)
                .then((credentials) => {
                    API.trackEvent(Constants.events.LOGIN);
                    credentials.user.getIdToken().then(token => {
                        data.setToken(token);
                        return controller.onLogin();
                    })
                })
                .catch((e) => API.ajaxHandler(store, e))
        },
        onLogin: () => {
            return controller.getProjects();
        },

        setUser: function (user) {
            if (!store.model && user) {
                AsyncStorage.getItem('selProjectId')
                    .then(selProjectId => {
                        store.model = user;
                        store.project = user && user.projects && (_.find(user.projects, {id: selProjectId}) || user.projects[0]);
                        store.loaded();
                    })

            } else {
                if (!user) {
                    firebase.auth().signOut().then(() => {
                      AsyncStorage.clear();
                      data.setToken(null);
                      store.model = user;
                      store.trigger('logout');
                    })
                }
            }
        },

        getProjects: () => {
          return data.get(`${Project.api}projects`)
              .then((res) => {
                  controller.setUser({
                      projects: res.results
                  });
              })
              .catch((e) => API.ajaxHandler(store, e))
        },

        selectProject: (id) => {
          AsyncStorage.setItem('selProjectId', id);
          store.project = _.find(store.model.projects, {id});
          store.changed();
        },

        createProject: (name) => {
          store.saving();
          API.trackEvent(Constants.events.CREATE_PROJECT);
          data.post(`${Project.api}projects`, {name})
              .then((res) => {
                  store.model.projects = store.model.projects.concat([res])
                  store.savedId = res.id;
                  store.saved();
              });
        },

        editProject: (config) => {
          API.trackEvent(Constants.events.EDIT_PROJECT);
          store.saving();
          data.put(`${Project.api}projects/${store.project.id}`, config)
              .then((res) => {
                  var idx = _.findIndex(store.model.projects, {id: store.project.id});
                  if (idx != -1) {
                      store.model.projects[idx] = res;
                      store.project = res
                  }
                  store.saved();
              });
        },

        deleteProject: () => {
            API.trackEvent(Constants.events.REMOVE_PROJECT);
            store.loading();
            data.delete(`${Project.api}projects/${store.project.id}`)
                .then((res) => {
                    store.model.projects.splice(_.findIndex(store.model.projects, {id: store.project.id}), 1);
                    store.project = store.model.projects && store.model.projects[0];
                    store.loaded();
                    store.changed();
                })
        },

        acceptInvite: (inviteId) => {
            store.saving();
            return data.post(`${Project.api}projects/${inviteId}/join`)
                .then((res) => {
                    store.savedId = res.id;
                    store.model.projects.push(res);
                    store.saved();
                })
                .catch((e) => API.ajaxHandler(store, e))

        },

        getProject: (id) => {
            data.get(`${Project.api}projects/${id}`)
                .then(res => {
                    const idx = _.findIndex(store.model.projects, {id});
                    if (idx !== -1) {
                        store.model.projects[idx] = res;
                        if (store.project.id === id) {
                            store.project = res;
                        }
                        store.changed();
                    }
                })
                .catch((e) => API.ajaxHandler(store, e))
        },

        deleteInvite: (inviteId) => {
            store.saving();
            return data.delete(`${Project.api}projects/${inviteId}/invite`)
                .then(res => {
                    var idx = _.findIndex(store.model.projects, {id: store.project.id});
                    if (idx != -1) {
                        store.model.projects[idx] = res;
                        store.project = res
                    }
                    store.saved();
                })
                .catch((e) => API.ajaxHandler(store, e))
        }
    },
    store = Object.assign({}, BaseStore, {
        id: 'account',
        getUser: function () {
            return store.model
        },
        getUserId: function () {
            return store.model && store.model.id
        },
        getProject: function () {
          return store.project;
        },
        clearError: function () {
            store.error = '';
            store.changed();
        }
    });

store.dispatcherIndex = Dispatcher.register(store, function (payload) {
    var action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.SET_USER:
            controller.setUser(action.user);
            break;
        case Actions.SET_TOKEN:
            controller.setToken(action.token);
            break;
        case Actions.LOGOUT:
            controller.setUser(null);
            break;
        case Actions.REGISTER:
            controller.register(action.details, action.isInvite);
            break;
        case Actions.LOGIN:
            controller.login(action.details);
            break;
        case Actions.GET_PROJECTS:
            controller.getProjects();
            break;
        case Actions.SELECT_PROJECT:
            controller.selectProject(action.id);
            break;
        case Actions.CREATE_PROJECT:
            controller.createProject(action.name);
            break;
        case Actions.EDIT_PROJECT:
            controller.editProject(action.config);
            break;
        case Actions.DELETE_PROJECT:
            controller.deleteProject();
            break;
        case Actions.ACCEPT_INVITE:
            controller.acceptInvite(action.id);
            break;
        case Actions.GET_PROJECT:
            controller.getProject(action.id);
            break;
        case Actions.DELETE_INVITE:
            controller.deleteInvite(action.id);
            break;
        default:
            return;
    }
});

controller.store = store;
module.exports = controller.store;
