import React from 'react';
import {Route, IndexRoute, Redirect} from 'react-router';

import App from './components/App';
import HomePage from './components/pages/HomePage';
import ExamplesPage from './components/pages/ExamplesPage';
import FormSettingsPage from './components/pages/FormSettingsPage';
import ProjectSettingsPage from './components/pages/ProjectSettingsPage';
import CreateProjectPage from './components/pages/CreateProjectPage';
import ResponsesPage from './components/pages/ResponsesPage';
import InvitePage from './components/pages/InvitePage';
import NotFoundPage from './components/pages/NotFoundPage';

export default (
  <Route path="/" component={App}>
    <IndexRoute component={HomePage}/>
    <Route path="/examples" component={ExamplesPage}/>
    <Route path="invite/:id" component={InvitePage}/>
    <Route path="/project/:projectId" exact={true} component={FormSettingsPage}/>
    <Route path="/project/:projectId/settings" exact={true} component={ProjectSettingsPage}/>
    <Route path="/create" component={CreateProjectPage}/>
    <Route path="/project/:projectId/responses" exact={true} component={ResponsesPage}/>
    <Route path="404" component={NotFoundPage}/>
    <Redirect from="*" to="404"/>
  </Route>
)