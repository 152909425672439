import React, {Component} from 'react';
import Select from 'react-select'
import data from '../../../common/data/base/_data'
import AccountStore from '../../../common/stores/account-store';

import Highlight from '../Highlight';

export default class ProjectSettingsPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            formData: {},
            enableRecaptcha: AccountStore.project ? AccountStore.project.enableRecaptcha : false,
            store: AccountStore.project ? AccountStore.project.store : false,
            includeReferer: AccountStore.project ? AccountStore.project.includeReferer : false
        };
        ES6Component(this);
    }


    static contextTypes = {
        router: React.PropTypes.object.isRequired
    };

    componentDidMount = () => {
        API.trackPage(Constants.pages.FORM_SETTINGS);
        this.listenTo(AccountStore, 'saved', () => {
            toast('Changes saved successfully');
        });
        this.listenTo(AccountStore, 'change', () => {
            this.setState({
                enableRecaptcha: AccountStore.project ? AccountStore.project.enableRecaptcha : false,
                to: '',
                emailTo: null,
                store: AccountStore.project ? AccountStore.project.store : false,
                includeReferer: AccountStore.project ? AccountStore.project.includeReferer : false
            });
        })
    };

    addEmail = () => {
        if (!this.state.emailTo) {
            return;
        }
        if (!Utils.isValidEmail(this.state.emailTo)) {
            this.setState({toError: 'Invalid email address'});
            this.toInput.focus();
            return;
        }
        var to = this.state.to && this.state.to.slice(0);
        if (!to) {
            to = AccountStore.project.to ? AccountStore.project.to.slice(0) : [];
        }
        if (to.indexOf(this.state.emailTo) !== -1) {
            this.setState({toError: 'This email address already exists'})
            this.toInput.focus();
            return;
        }
        to.push(this.state.emailTo);
        this.setState({emailTo: '', to});
        this.toInput.focus();
    }

    removeEmail = (email) => {
        var to = this.state.to && this.state.to.slice(0);
        if (!to) {
            to = AccountStore.project.to ? AccountStore.project.to.slice(0) : [];
        }
        to.splice(to.indexOf(email), 1);
        this.setState({to});
    }

    renderEmail = (email) => {
        return (
            <Row key={email}>
                <Flex>{email}</Flex>
                <Button onClick={() => this.removeEmail(email)}>Remove</Button>
            </Row>
        )
    }

    changed = () => {
        const original = AccountStore.project;

        if (this.state.from !== original.from) {
            return true;
        }

        if (this.state.subject && this.state.subject !== original.subject) {
            return true;
        }

        if (this.state.to) {
            if (!original.to || this.state.to.length !== original.to.length) {
                return true;
            }

            for (var i = 0; i < this.state.to.length; i++) {
                if (this.state.to[i] !== original.to[i]) {
                    return true;
                }
            }
        }

        if (this.state.store !== original.store) {
            return true;
        }

        if (this.state.includeReferer !== original.includeReferer) {
            return true;
        }

        if (this.state.enableRecaptcha !== original.enableRecaptcha) {
            return true;
        }

        if (this.state.enableRecaptcha) {
            if (this.state.recaptchaSecretKey && this.state.recaptchaSecretKey !== original.recaptchaSecretKey) {
                return true;
            }
        }

        return false;
    }

    valid = () => {
        const original = AccountStore.project;

        if (!this.state.subject && !original.subject) {
            return false;
        }

        if (this.state.from && !Utils.isValidEmail(this.state.from)) {
            return false;
        }

        if (!this.state.to && !original.to) {
            return false;
        }

        if (this.state.to && this.state.to.length === 0) {
            return false;
        }

        if (this.state.enableRecaptcha) {
            if (!this.state.recaptchaSecretKey && !original.recaptchaSecretKey) {
                return false;
            }
        }

        return true;
    }

    save = () => {
        const original = AccountStore.project;

        AppActions.editProject({
            name: original.name,
            subject: this.state.subject || original.subject,
            from: this.state.from || original.from,
            to: this.state.to || original.to,
            store: this.state.store,
            enableRecaptcha: this.state.enableRecaptcha,
            recaptchaSecretKey: this.state.enableRecaptcha ? this.state.recaptchaSecretKey || original.recaptchaSecretKey : original.recaptchaSecretKey,
            includeReferer: this.state.includeReferer
        });
    }

    updateForm = (name, e) => {
        const { state: { formData }} = this;
        formData[name] = Utils.safeParseEventValue(e);
        this.setState({formData})
    }

    submit = (projectId) => {
        if (this.state.isSubmitting) {
            return;
        }
        const url = `https://3fbs97w0nb.execute-api.eu-west-1.amazonaws.com/dev/${projectId}`;
        this.setState({isSubmitting: true});
        data.post(url, this.state.formData)
            .then(()=>{
                this.setState({isSubmitting: false});
                toast("Your response has been submitted");
            })
            .catch((e)=>{
                if (e && e.json) {
                    e.json()
                        .then(({error})=> {
                            this.setState({isSubmitting: false});
                            toast("Error submitting your form: " + error );
                        })
                        .catch(()=>{
                            toast("Error submitting your form" );
                        })
                } else {
                    this.setState({isSubmitting: false});
                    toast("Error submitting your form" );
                }
            })
    }

    render() {
        return (
            <div id="project-settings-page" className="container app-container">
                <AccountProvider onLogout={this.onLogout} onLogin={this.onLogin}>
                    {({isLoading, project, isSaving, user, error}) => (
                        <div key={project.id}>
                            <FormGroup>
                               <Panel
                                    className={this.props.className}
                                    icon={"ion-ios-settings"}
                                    title={"Configure"}
                                >
                                    <FormGroup>
                                        <InputGroup
                                            inputProps={{
                                                defaultValue: project.subject,
                                                name: 'subject',
                                                className: "full-width"
                                            }}
                                            title="Subject"
                                            placeholder=""
                                            onChange={(e) => this.setState({subject: Utils.safeParseEventValue(e)})}
                                        />
                                        <InputGroup
                                            inputProps={{defaultValue: project.from, name: 'from', className: "full-width"}}
                                            title="From"
                                            placeholder=""
                                            onChange={(e) => this.setState({from: Utils.safeParseEventValue(e)})}
                                        />
                                        <form id="form" name="form" onSubmit={(e) => {
                                            Utils.preventDefault(e);
                                            this.addEmail();
                                        }}>
                                            <Row>
                                                <Flex>
                                                    <label className="cols-sm-2 control-label">To</label>
                                                    <Select
                                                        options={_.map(project.users, user => ({
                                                            value: user.email,
                                                            label: user.email
                                                        }))}
                                                        value={_.map(this.state.to ? this.state.to : project.to, email => ({
                                                            value: email,
                                                            label: email
                                                        }))}
                                                        isMulti
                                                        onChange={options => this.setState({to: _.map(options, 'value')})}
                                                    />
                                                </Flex>
                                            </Row>
                                        </form>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row space>
                                            <div>
                                                <label>Store form responses?</label>
                                                <p>
                                                    View form responses in the formly Inbox rather than scanning your emails.
                                                </p>
                                            </div>
                                            <Switch
                                                checked={this.state.store}
                                                onChange={(store) => this.setState({store})}
                                            />
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row space>
                                            <div>
                                                <label>Enable <a href="https://www.google.com/recaptcha/intro/v3.html" target="_blank">reCAPTCHA verification</a>?</label>
                                                <p>
                                                    Add an extra layer of security to make sure noone is spamming your form.
                                                </p>
                                            </div>
                                            <Switch
                                                checked={this.state.enableRecaptcha}
                                                onChange={(enableRecaptcha) => this.setState({enableRecaptcha})}
                                            />
                                        </Row>
                                    </FormGroup>
                                    {this.state.enableRecaptcha && (
                                        <div className="indent">
                                            <p>Generate your API keys for reCAPTCHA <a target="_blank"
                                                                                       href='https://www.google.com/recaptcha/admin'>here</a>.
                                            </p>
                                            <p>Paste this snippet before the closing <code>{`</head>`}</code> tag on your
                                                site.</p>
                                            <Highlight className="html">
                                                {`<script src='https://www.google.com/recaptcha/api.js'></script>`}
                                            </Highlight>
                                            <p>Paste this snippet at the end of the <code>{`<form>`}</code> where you want
                                                the reCAPTCHA widget to appear.</p>
                                            <Highlight className="html">
                                                {`<div class="g-recaptcha" data-sitekey="your-public-key-here"></div>`}
                                            </Highlight>
                                            <p>If you are using a library for rendering out your frontend such as React then
                                                you may need to find a module that dynamically loads the reCAPTCHA script
                                                (such as <a target="_blank"
                                                            href="https://www.npmjs.com/package/react-google-recaptcha">react-google-recaptcha</a>)
                                            </p>
                                            <FormGroup>
                                                <InputGroup
                                                    inputProps={{
                                                        defaultValue: project.recaptchaSecretKey,
                                                        name: 'recaptcha-secret-key',
                                                        className: "full-width"
                                                    }}
                                                    title="Secret Key"
                                                    placeholder=""
                                                    onChange={(e) => this.setState({recaptchaSecretKey: Utils.safeParseEventValue(e)})}
                                                />
                                            </FormGroup>
                                        </div>
                                    )}
                                    <FormGroup>
                                        <Row space>
                                            <div>
                                                <label>Include referer?</label>
                                                <p>
                                                    Keep track of what url your form was submitted from.
                                                </p>
                                            </div>
                                            <Switch
                                                checked={this.state.includeReferer}
                                                onChange={(includeReferer) => this.setState({includeReferer})}
                                            />
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="text-right">
                                        <Button disabled={!this.valid() || !this.changed()} onClick={this.save}>
                                            {isSaving ? 'Saving' : 'Save'}
                                        </Button>
                                    </FormGroup>
                                    {error && <div className={"text-danger"}>{error}</div>}
                                </Panel>
                            </FormGroup>
                            <FormGroup>
                                <Panel icon="ion-ios-rocket" title="Try It">
                                    <div>
                                        <Highlight className="html">
                                            {
                                                `
<form action="https://post.formlyapp.com/${project.id}" method="POST">
    <input type="text" name="name" />
    <input type="email" name="email" />
    <textarea name="feedback"></textarea>${this.state.enableRecaptcha ? `\n  <div class="g-recaptcha" data-sitekey="${this.state.captchaKey || "your-public-key-here"}"></div>` : ''}
    <input type="submit" value="Send" />
</form>`
                                            }
                                        </Highlight>
                                        <FormGroup>
                                            <form onSubmit={(e)=>{
                                                Utils.preventDefault(e);this.submit(project.id)
                                            }} className="example-form">
                                                <h3>{project.name} Example Form</h3>
                                                <FormGroup className="input-container full-width">
                                                <input onChange={(e)=>this.updateForm("name", e)} placeholder="Name" className="input" type="text" name="name" />
                                                </FormGroup>
                                                <FormGroup className="input-container full-width">
                                                <input onChange={(e)=>this.updateForm("email", e)} placeholder="Email Address" className="input" type="email" name="email" />
                                                </FormGroup>
                                                <FormGroup className="input-container full-width">
                                                    <textarea onChange={(e)=>this.updateForm("feedback", e)} rows={3} placeholder="Feedback" className="input" name="feedback"></textarea>{this.state.enableRecaptcha ? `\n  <div class="g-recaptcha" data-sitekey="your-public-key-here"></div>` : ''}
                                                </FormGroup>
                                                {this.state.submit && (
                                                    <div className="alert alert-success">
                                                        Your form has been submitted
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <div className="input-container full-width">
                                                        {this.state.isSubmitting ? (
                                                            <button  disabled className="btn btn-primary full-width" type="submit">Sending...</button>
                                                        ): (
                                                            <button className="btn btn-primary full-width" type="submit">Send</button>
                                                        )}
                                                    </div>
                                                </FormGroup>
                                            </form>
                                        </FormGroup>
                                    </div>
                                </Panel>
                            </FormGroup>
                        </div>
                    )}
                </AccountProvider>
            </div>
        );
    }
}
