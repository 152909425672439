var BaseStore = require('./base/_store');
var data = require('../data/base/_data');

import AccountStore from './account-store';

var controller = {
        getResponses: (startAt, pageSize) => {
            store.loading();
            return data.get(`${Project.api}responses`, {
                projectId: AccountStore.project.id,
                startAt,
                pageSize
            })
                .then((res) => {
                    store.model = res.results;
                    if (!startAt) {
                        store.history = [''];
                    } else {
                        store.history.push(store.next);
                    }
                    store.next = res.next;
                    store.loaded();
                })
                .catch((e) => API.ajaxHandler(store, e))
        }
    },
    store = Object.assign({}, BaseStore, {
        id: 'responses',
        getResponses: function () {
            return store.model
        },
        hasMore: function () {
            return store.next ? true : false;
        },
        hasPrev: function () {
            return store.history && store.history.length > 1;
        }
    });

store.dispatcherIndex = Dispatcher.register(store, function (payload) {
    var action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_RESPONSES:
            controller.getResponses(action.startAt, action.pageSize);
            break;
        case Actions.GET_NEXT_RESPONSES:
            if (!store.next) return;
            controller.getResponses(store.next, action.pageSize);
            break;
        case Actions.GET_PREV_RESPONSES:
            if (!store.history || store.history.length <= 1) return;
            store.history.pop();
            controller.getResponses(store.history[store.history.length - 1], action.pageSize);
            break;
        default:
            return;
    }
});

controller.store = store;
module.exports = controller.store;
