module.exports = Object.assign({}, require('./base/_action-constants'), {
	"CREATE_PROJECT": "CREATE_PROJECT",
	"GET_PROJECT": "GET_PROJECT",
	"SELECT_PROJECT": "SELECT_PROJECT",
	"EDIT_PROJECT": "EDIT_PROJECT",
	"SET_USER_FLAG": "SET_USER_FLAG",
	"GET_RESPONSES": "GET_RESPONSES",
	"GET_PREV_RESPONSES": "GET_PREV_RESPONSES",
	"GET_NEXT_RESPONSES": "GET_NEXT_RESPONSES",
	"DELETE_PROJECT": "DELETE_PROJECT",
	"ACCEPT_INVITE": "ACCEPT_INVITE",
	"DELETE_INVITE": "DELETE_INVITE",
});
