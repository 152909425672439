import React, { Component, PropTypes } from "react";
import AccountStore from "../stores/account-store";
import data from "../../common/data/base/_data";

const TheComponent = class extends Component {
  static displayName = "TheComponent";

  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: AccountStore.isLoading,
      project: AccountStore.getProject(),
      user: AccountStore.getUser()
    };
  }

  componentDidMount() {
    ES6Component(this);
    this.listenTo(AccountStore, "change", () => {
      this.setState({
        isLoading: AccountStore.isLoading,
        isSaving: AccountStore.isSaving,
        user: AccountStore.getUser(),
        project: AccountStore.getProject(),
        error: AccountStore.error
      });
    });

    this.listenTo(AccountStore, "loaded", () => {
      this.props.onLogin && this.props.onLogin();
    });

    this.listenTo(AccountStore, "saved", () => {
      this.props.onSave && this.props.onSave(AccountStore.savedId);
    });

    this.listenTo(AccountStore, "logout", () => {
      this.setState({
        isLoading: false,
        isSaving: false,
        project: AccountStore.getProject(),
        user: AccountStore.getUser()
      });
      this.props.onLogout && this.props.onLogout();
    });
    this.listenTo(AccountStore, "no-user", () => {
      this.setState({
        isSaving: false,
        isLoading: false,
        project: AccountStore.getProject(),
        user: AccountStore.getUser()
      });
      this.props.onNoUser && this.props.onNoUser();
    });
    this.listenTo(AccountStore, "problem", () => {
      this.setState({
        isLoading: AccountStore.isLoading,
        isSaving: AccountStore.isSaving,
        error: AccountStore.error
      });
    });
  }

  login = details => {
    AppActions.login(details);
  };

  loginDemo = () => {
    AppActions.login(Project.demoAccount);
  };

  register = details => {
    AppActions.register(details);
  };

  inviteUsers = emailAddresses => {
    this.setState({ isSaving: true });

    data
      .post(`${Project.api}projects/${this.state.project.id}/invite/`, {
        emails: emailAddresses.split(",").map(e => {
          API.trackEvent(Constants.events.INVITE);
          return e.trim();
        }),
        frontend_base_url: this.props.baseURL
      })
      .then(() => {
        this.setState({ isSaving: false });
        toast("Invites sent successfully");
        this.props.onSave && this.props.onSave();
        AppActions.getProject(this.state.project.id);
      });
  };

  render() {
    var { isLoading, isSaving, user, project, error } = this.state;
    return this.props.children(
      {
        isLoading,
        isSaving,
        user,
        project,
        error
      },
      {
        login: this.login,
        register: this.register,
        selectProject: AppActions.selectProject,
        createProject: AppActions.createProject,
        editProject: AppActions.editProject,
        deleteProject: AppActions.deleteProject,
        inviteUsers: this.inviteUsers
      }
    );
  }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
