const TheComponent = class extends React.Component {
	displayName: 'TheComponent'

	constructor(props, context) {
		super(props, context);
		this.state = { value: props.defaultValue };
	}

	toggle = () => {
		this.setState({ value: !this.state.value });
	}


	render() {
		return (
			<div>
				<div className={"hero"}>
					<div className="col-md-9 offset-md-2 text-center">
						<h1>Contact Forms, Simplified</h1>
						<p className="hero__cta-text">
							Send your form data to Formly and we'll forward it on to any number of email addresses.
						</p>
						<a className="btn btn-lg btn-primary" onClick={this.props.scrollToSignUp}>Sign up</a>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
};

TheComponent.propTypes = {
	children: RequiredElement,
	toggleComponent: OptionalFunc,
	title: RequiredString,
	defaultValue: OptionalBool,
};

module.exports = TheComponent;
