const keywords = {
    URL_CLIENT: "https://cdn.jsdelivr.net/npm/bullet-train-client/lib/index.js",
    LIB_NAME: "bulletTrain",
    NPM_CLIENT: "bullet-train-client",
    NPM_RN_CLIENT: "react-native-bullet-train",
    USER_ID: "bullet_train_sample_user",
    FEATURE_FUNCTION: "myCoolFeature",
    FEATURE_NAME: "myCoolFeature",
    USER_FEATURE_FUNCTION: "myPowerUserFeature",
    USER_FEATURE_NAME: "myPowerUserFeature",
    FEATURE_NAME_ALT: "bannerSize",
    FEATURE_NAME_ALT_VALUE: "big",
};


var Constants = {
    events: {
        "CREATE_PROJECT": {"event": "Project created", "category": "Project"},
        "EDIT_PROJECT": {"event": "Project edited", "category": "Project"},
        "LOGIN": {"event": "User login", "category": "User"},
        "REGISTER": {"event": "User register", "category": "User"},
        "REMOVE_PROJECT": {"event": "Project removed", "category": "Project"},
        "INVITE": {"event": "User invite", "category": "Project"},
    },
    pages: {
        "CREATE_PROJECT": "Create Project Page",
        "DOCUMENTATION": "Documentation Page",
        "HOME": "Home Page",
        "NOT_FOUND": "404 Page",
        "PROJECT_SETTINGS": "Project Settings Page",
        "USER": "User Page",
        "RESPONSES": "Responses Page",
        "INVITE": "Invite Page",
        "FORM_SETTINGS": "Form Settings Page",
    },
    strings: {
    },
    codeHelp: {
        keys: {
            "JavaScript": "javascript",
            "React Native": "javascript"
        },

        // "CREATE_USER": (envId) => {
        //     return {
        //         "JavaScript": require('./code-help/create-user/create-user-js')(envId, keywords),
        //         "React Native": require('./code-help/create-user/create-user-rn')(envId, keywords),
        //         "Java": `Coming soon`,
        //         ".NET": "Coming soon"
        //     }
        // },
    }
};

module.exports = Constants;
