import React, { Component } from 'react';
import { Link } from 'react-router';

import AccountStore from '../../../common/stores/account-store';

import Highlight from '../Highlight';
import ListView from '../ListView';

const pageSize = 5;

export default class ResponsesPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      pages: _.range(0, 100000).map((page) => _.range(page * pageSize, page * pageSize + pageSize)),
      currentPage: 1
    };
    ES6Component(this);
  }


  static contextTypes = {
    router: React.PropTypes.object.isRequired
  };

  componentDidMount = () => {
    API.trackPage(Constants.pages.RESPONSES);
    AppActions.getResponses('', pageSize);
  };

  renderRow = (response) => (
    <div className="list-item">
      <Row>
        <h5>Time:</h5>
        <div style={{marginLeft: 5}}>{moment(response.timestamp).format('YYYY-MM-DD HH:mm:ss')}</div>
      </Row>
      {_.map(response, (value, key) => {
        if (key === 'timestamp' || key === 'id') return null;
        return (
          <Row>
            <h5>{key}: </h5>
            <div style={{marginLeft: 5}}>{value}</div>
          </Row>
        );
      })}
      <hr />
    </div>
  )

  render() {
    return (
      <div id="responses-page" className="container app-container">
        <ResponsesProvider>
          {({ isLoading, responses, error, hasMore, hasPrev }, { getResponses }) => (
            <div>
              <h3>Inbox</h3>
              <p>
                View all of your form submissions here in once place rather than scanning your email.
              </p>
              {isLoading ? <Loader /> : responses && responses.length ? (
                <div>
                  <ListView
                    ref="list"
                    data={responses}
                    renderRow={this.renderRow}
                  />
                  {hasMore && <Button onClick={() => AppActions.getNextResponses(pageSize)}>Next</Button>}
                  {hasPrev && <Button onClick={() => AppActions.getPrevResponses(pageSize)}>Prev</Button>}
                </div>
              ) : (
                  <FormGroup>
                    <div className="text-center">
                      <hr/>
                        Nobody has submitted your form yet...
                    </div>
                  </FormGroup>
              )}
            </div>
          )}
        </ResponsesProvider>
      </div>
    );
  }
}
