import React, { Component, PropTypes } from 'react';
import AccountStore from '../../common/stores/account-store';
import ProjectSelect from './ProjectSelect';
import Feedback from './Feedback';
import Aside from './Aside';

export default class App extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  };

  static contextTypes = {
    router: React.PropTypes.object.isRequired
  };

  state = {}

  onLogin = () => {
    const { redirect } = this.props.location.query;

    const project = AccountStore.getProject();
    if (!project && document.location.href.indexOf('invite') === -1) { //If user has no project redirect to /create
      this.context.router.replace('/create');
      return
    }

    //Redirect on login
    if (this.props.location.pathname == '/' || this.props.location.pathname == '/login' || this.props.location.pathname == '/signup') {
      this.context.router.replace(redirect ? redirect : '/project/' + project.id);
    }
  };

  onLogout = () => {
    this.context.router.replace('/');
  };

  componentDidMount() {
    firebase.auth().onIdTokenChanged(user => {
      if (user) {
        user.getIdToken().then(token => {
          AppActions.setToken(token);
        });
      }
    });
  }

  toggleRegister = (register) => {
    AccountStore.clearError();
    this.setState({ register });
  }

  feedback = () => {
    openModal('Feedback', <Feedback />)
  }

  render() {
    const redirect = this.props.location.query.redirect ? `?redirect=${this.props.location.query.redirect}` : "";
    const { email, password, repeatPassword } = this.state;
    const pageHasAside = this.props.location.pathname.indexOf('/project') !== -1 || this.props.location.pathname.indexOf('/responses') !== -1;

    return (
      <div>
        <div className={pageHasAside && "hidden"}>

          <nav className="navbar navbar__master-brand">
            <div className="navbar-left">
              <div className="navbar-nav">

                <a href="https://labs.solidstategroup.com/" target="__blank" className="nav-item nav-item-brand nav-link">
                  <div className="nav-item-brand">
                    <img style={{width: 250}}  src="/images/products-nav/labs-logo-dark.svg"/>
                  </div>
                </a>

              </div>
            </div>
            <div className="navbar-right">
              <Popover style={{padding: 10}} className="popover-right"
                       renderTitle={(toggle, isActive) => (
                           <div className="products-button" onClick={toggle}>Products<ion className={isActive ? "ion-ios-arrow-dropup" : "ion-ios-arrow-dropdown"} /></div>
                       )}>
                {(toggle) => (
                   <div>
                     <a href="https://bullet-train.io/" target="__blank" className="product-item">
                       <img src="/images/products-nav/bullet-train-logo-mark.svg"/>
                       <h5>Bullet Train</h5>
                       <small>Release features with confidence</small>
                     </a>
                     <a href="https://formlyapp.com/" target="__blank" className="product-item">
                       <img src="/images/products-nav/formly-logo-mark-colour.svg"/>
                       <h5>Formly</h5>
                       <small>Contact Forms, Simplified</small>
                     </a>
                     <a href="https://uptimely.app/" target="__blank" className="product-item">
                       <img src="/images/products-nav/uptimely-logo-mark-colour-filled.svg"/>
                       <h5>Uptimely</h5>
                       <small>Website Monitoring. Sorted.</small>
                     </a>
                   </div>
                )}
              </Popover>
            </div>
          </nav>

        </div>
        <AccountProvider onLogout={this.onLogout} onLogin={this.onLogin}>
          {({ isLoading, user, project, error }) => (
            <div className={pageHasAside && "aside-body"}>
              <nav className={"navbar navbar-light"}>
                <div className="navbar-left">
                  <div className="navbar-nav">
                    <Link to={"/"} className="nav-item nav-item-brand nav-link">
                        <div className="nav-item-brand">
                          <img className={pageHasAside && "hidden"} src="/images/formly-logo-new.svg" />
                        </div>
                    </Link>
                  </div>
                </div>
                <div className="navbar-right">
                  {user ? (
                    <div className="flex-column project-nav">
                      <Popover className="popover-right"
                        renderTitle={(toggle) => project ? (
                          <a id="project-menu" onClick={toggle}>
                            {project.name}
                            <div className="flex-column ion ion-ios-arrow-down" />
                          </a>
                        ) : (
                          <a id="project-menu" onClick={toggle}>
                            Create Project
                            <div className="flex-column ion ion-ios-arrow-down" />
                          </a>
                        )}>
                        {(toggle) => (
                          <div>

                            {project && (
                              <ProjectSelect
                                clearableValue={false}
                                onChange={(project) => {
                                  toggle();
                                  AppActions.selectProject(project.id);
                                  this.context.router.push('/project/' + project.id);
                                }} />
                            )}
                            <div>
                              <Link id="create-project-link" onClick={toggle} to="/create">
                                Create Project
                              </Link>
                            </div>

                            <a id="logout-link" href="#"
                              onClick={() => AppActions.setUser(null)}
                              to="exampleone">Logout</a>
                          </div>
                        )}
                      </Popover>
                    </div>
                  ) : (
                      <div className="navbar-nav">
                        <ul className="nav-list list-unstyled">
                          <li><a href="https://docs.formlyapp.com/">Docs</a></li>
                          <li><Popover className="auth-popover-right"
                            renderTitle={(toggle, isActive) => (
                              <div className="login-button" onClick={toggle}>{this.state.register ? 'Register' : 'Login'} <ion className={isActive ? "ion-ios-arrow-dropup" : "ion-ios-arrow-dropdown"} /></div>
                            )}>
                            {(toggle) => (
                              <div className="container">
                                <form id="form" name="form" onSubmit={(e) => {
                                  Utils.preventDefault(e);
                                  this.state.register ? AppActions.register({ email, password, password2: repeatPassword }) : AppActions.login({ email, password });
                                }}>
                                  <FormGroup>
                                    <InputGroup
                                      inputProps={{ name: 'email', className: "full-width" }}
                                      title="Email"
                                      placeholder=""
                                      onChange={(e) => this.setState({ email: Utils.safeParseEventValue(e) })}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <InputGroup
                                      type="password"
                                      inputProps={{ name: 'password', className: "full-width" }}
                                      title="Password"
                                      placeholder=""
                                      onChange={(e) => this.setState({ password: Utils.safeParseEventValue(e) })}
                                    />
                                  </FormGroup>
                                  {this.state.register && (
                                    <FormGroup>
                                      <InputGroup
                                        type="password"
                                        inputProps={{ name: 'repeat-password', className: "full-width" }}
                                        title="Repeat password"
                                        placeholder=""
                                        onChange={(e) => this.setState({ repeatPassword: Utils.safeParseEventValue(e) })}
                                      />
                                    </FormGroup>
                                  )}
                                  {error && <div className="text-danger">{error}</div>}
                                  <FormGroup>
                                    {!this.state.register ? (
                                      <Button
                                        type="submit"
                                        disabled={!this.state.email || !this.state.password}
                                        className="btn-primary float-right" onClick={() => AppActions.login({ email: this.state.email, password: this.state.password })}>
                                        Login
                                    </Button>
                                    ) : (
                                        <Button
                                          type="submit"
                                          disabled={!this.state.email || !this.state.password || !this.state.repeatPassword}
                                          className="btn-primary float-right" onClick={() => AppActions.register({ email: this.state.email, password: this.state.password, password2: this.state.repeatPassword })}>
                                          Register
                                    </Button>
                                      )}
                                  </FormGroup>
                                  <div style={{ clear: 'both', paddingTop: '1em', paddingBottom: '1em' }}>
                                    {!this.state.register ? <a onClick={() => this.toggleRegister(true)}>Don't have an account?</a> : <a onClick={() => this.toggleRegister(false)}>Already have an account?</a>}
                                  </div>
                                </form>
                              </div>
                            )}
                          </Popover></li>
                        </ul>
                      </div>
                    )}
                </div>
              </nav>
              {pageHasAside && (
                  <Aside projectId={project && project.id} />
              )}
              {this.props.children}
            </div>
          )}
        </AccountProvider>
        <div className="feedback-btn">
          <Button onClick={this.feedback} className="btn btn-primary feedback-btn">Give Us Feedback</Button>
        </div>
      </div>
    );
  }
}
