import React, {Component} from 'react';
import {Link} from 'react-router';

export default class CreateProjectPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {name: ''};
	}


	static contextTypes = {
		router: React.PropTypes.object.isRequired
	};

	componentDidMount = () => {
        API.trackPage(Constants.pages.CREATE_PROJECT);
        this.input.focus()
	};

	onSave = (id) => {
		AppActions.selectProject(id);
		this.context.router.push('/project/' + id);
	};

	render() {
		return (
			<div id="create-project-page" className="container app-container">
				<h3>
					Create your project
				</h3>
				<p>
					Projects allow you to post a form directly to us by changing the action and it allows use of reCAPTCHA verification.
				</p>
				<AccountProvider onSave={this.onSave}>
					{({isSaving}, {selectProject, createProject}) => (
						<form onSubmit={(e) => {
							e.preventDefault();
							createProject(this.state.name);
						}}>
								<InputGroup
									ref={(e) => this.input = e}
									inputProps={{name: 'project-name', className: 'full-width'}}
									title="Project Name"
									placeholder="E.g. formly"
									onChange={(e) => this.setState({name: Utils.safeParseEventValue(e)})}/>
								<div className="text-right">
									<Button disabled={isSaving || !this.state.name} id="create-project-btn">
										Create
									</Button>
								</div>
						</form>
					)}
				</AccountProvider>
			</div>
		);
	}
}
