global.API = {
    ajaxHandler: function (store, res) {
        switch (res.status) {
            case 404:
                // ErrorModal(null, 'API Not found: ');
                break;
            case 503:
                // ErrorModal(null, error);
                break;
            default:
            // ErrorModal(null, error);
        }

        if (store) {
            if (res.code) {
                switch (res.code) {
                    case 'auth/invalid-email':
                        store.error = 'The email address entered is invalid';
                        break;
                    case 'auth/wrong-password':
                    case 'auth/user-not-found':
                        store.error = 'The username or password you entered is incorrect';
                        break;
                    default:
                        store.error = '';
                        break;
                }
            } else {
                store.error = res.err || '';
            }
            store.goneABitWest();
        }
    },
    trackEvent: function(data) {
        if (Project.ga) {
            console.info("track", data);
            if (!data || !data.category || !data.event) {
                console.error("Invalid event provided", data);
            }
            ga('send', {
                hitType: 'event',
                eventCategory: data.category,
                eventAction: data.event,
                eventLabel: data.label
            });
        }

    },
    trackPage: function(title) {
        if (Project.ga) {
            ga('send', {
                hitType: 'pageview',
                title,
                location: document.location.href,
                page: document.location.pathname
            });
        }
    },
    log() {
        console.log.apply(this,arguments)
    }
};