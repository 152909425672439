module.exports = {
  debug: false,
  env: 'prod',
  api: 'https://formly-back-end-prod.appspot.com/',
  ga: 'UA-129346689-1',
  recaptchaSiteKey: '6LcVyV4UAAAAAJHVSkyCBokZkqcrjO7aKRU9GkHu',
  firebaseConfig: {
    apiKey: "AIzaSyBVJsA8OHIRwiJU2Hsh4Zn64FcsKg6eC3Y",
    authDomain: "form-serverless.firebaseapp.com",
    databaseURL: "https://form-serverless.firebaseio.com",
    projectId: "form-serverless",
    storageBucket: "form-serverless.appspot.com",
    messagingSenderId: "141104745538"
  }
};
