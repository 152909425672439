import React, { Component } from 'react';
import { Link } from 'react-router';

import AccountStore from '../../../common/stores/account-store';
import ConfirmRemoveProject from '../modals/ConfirmRemoveProject';
import InviteUsersModal from '../modals/InviteUsers';

import Highlight from '../Highlight';

export default class ProjectSettingsPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
    };
    ES6Component(this);
  }

  static contextTypes = {
    router: React.PropTypes.object.isRequired
  };

  componentDidMount = () => {
    API.trackPage(Constants.pages.PROJECT_SETTINGS);
    this.listenTo(AccountStore, 'saved', () => {
      toast('Changes saved successfully');
    });
    this.listenTo(AccountStore, 'change', () => {
      const project = AccountStore.getProject();
      if (!project || project.id !== this.props.params.projectId) {
        this.context.router.replace(project ? '/project/' + project.id : '/');
      }
    })
  };

  confirmRemove = (project, cb) => {
		openModal("Remove Project", <ConfirmRemoveProject
			project={project}
			cb={cb}/>)
	};

  save = () => {
    const original = AccountStore.project;

    AppActions.editProject(Object.assign({}, original, {
      name: this.state.name
    }));
  }

  deleteInvite = (inviteId) => {
    if (!inviteId) return;
    openConfirm(<h2>Confirm</h2>, <p>Are you sure you want to delete this invite?</p>, () => AppActions.deleteInvite(inviteId));
  }

  render() {
    const {name} = this.state;
    return (
      <div id="project-settings-page" className="container app-container">
        <AccountProvider onLogout={this.onLogout} onLogin={this.onLogin}>
          {({ isLoading, isSaving, project, error }, {deleteProject}) => (
            <div key={project.id}>
              <FormGroup>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  !isSaving && name && this.save();
                }}>
                  <InputGroup
                    ref={(e) => this.input = e}
                    inputProps={{ name: "proj-name", defaultValue: project.name, className: "full-width" }}
                    onChange={(e) => this.setState({ name: Utils.safeParseEventValue(e) })}
                    isValid={name && name.length}
                    type="text" title={<h3>Project Name</h3>}
                    placeholder="My Product Name" />
                  <div className="text-right">
                    <Button id="save-proj-btn" disabled={isSaving || !name}>
                      {isSaving ? 'Saving' : 'Save'}
                    </Button>
                  </div>
                </form>
              </FormGroup>
              <FormGroup>
                <strong>
                  Delete Project
										</strong>
                <p>
                  This project will be deleted permanently
										</p>
                <Button
                  onClick={() => this.confirmRemove(project, () => {
                    deleteProject(this.props.params.projectId)
                  })}
                  className={"btn btn-danger"}>
                  Delete
										</Button>
              </FormGroup>
              {error && <div className={"text-danger"}>{error}</div>}
              <div className="margin-top">
								<h3>Project members</h3>
								<p>
									Invite email addresses, comma separated
								</p>
								{isSaving && <div className="centered-container"><Loader/></div>}
								{!isSaving && (
									<div>
										<FormGroup>
											<PanelSearch
												id="project-members-list"
												title="Users"
												className={"no-pad"}
												items={project.users ? _.values(project.users) : []}
												renderRow={({email, pendingInvite, inviteId}) =>
													<Row className={"list-item"} key={email}>
                            <Flex>{email + (pendingInvite ? ' <Pending Invite>' : '')} {email == AccountStore.getUser().email && "(You)"}</Flex>
                            {pendingInvite && inviteId ? <Button onClick={() => this.deleteInvite(inviteId)}>Remove</Button> : null}
													</Row>
												}
												renderNoResults={<div>You have no users in this project.</div>}
												filterRow={(item, search) => {
													//TODO:
													return true;
												}}
											/>
										</FormGroup>

										<div className="text-right">
											<Button id={"btn-invite"} onClick={() => openModal(<InviteUsersModal/>)}>
												Invite Users
											</Button>
										</div>

									</div>
                )}
            </div>
            </div>
          )}
        </AccountProvider>
      </div>
    );
  }
}
