/**
 * Created by kyle-ssg on 10/02/15.
 */
module.exports = {
  "GET_CONFIG": "GET_CONFIG",
  "REGISTER_NOTIFICATIONS": "REGISTER_NOTIFICATIONS",
  "LOGIN": "LOGIN",
  "REGISTER": "REGISTER",
  "SET_USER": "SET_USER",
  "SET_TOKEN": "SET_TOKEN",
  "REFRESH": "REFRESH",
};
