import './project/polyfil';
import './project/libs';
import './project/api';
import './project/project-components';
import './styles/styles.scss';

window.Project = require('../common/project');
window.Utils = require('../common/utils/utils');
window.Constants = require('../common/constants');

window.openModal = require('./project/modals').openModal;
window.openConfirm = require('./project/modals').openConfirm;

import {Router, browserHistory} from 'react-router';
import routes from './routes';

import ToastMessages from './project/toast';

import AccountStore from '../common/stores/account-store';

//redirect before login
if (document.location.pathname!='/' && document.location.pathname!='/login' && document.location.pathname!='/signup' && !AccountStore.getUser()) {
  browserHistory.push('/?redirect='+encodeURIComponent(document.location.pathname));
}
ReactDOM.render(
  <Router history={browserHistory} routes={routes}/>,
  document.getElementById('app')
);

//Setup for toast messages
ReactDOM.render(<ToastMessages />, document.getElementById('toast'));