import React, {Component, PropTypes} from 'react';
import ResponsesStore from '../stores/responses-store';

const TheComponent = class extends Component {
	displayName: 'TheComponent'

	constructor(props, context) {
		super(props, context);
		this.state = {
      isLoading: ResponsesStore.isLoading,
      responses: ResponsesStore.getResponses() || []
		};
	}

	componentDidMount() {
		ES6Component(this);
		this.listenTo(ResponsesStore, 'change', () => {
			this.setState({
				isLoading: ResponsesStore.isLoading,
        responses: ResponsesStore.getResponses(),
				error: ResponsesStore.error,
				hasMore: ResponsesStore.hasMore(),
				hasPrev: ResponsesStore.hasPrev()
			});
		});

		this.listenTo(ResponsesStore, 'loaded', () => {
			this.setState({
				isLoading: ResponsesStore.isLoading,
        responses: ResponsesStore.getResponses(),
				error: ResponsesStore.error,
				hasMore: ResponsesStore.hasMore(),
				hasPrev: ResponsesStore.hasPrev()
			});
		})

		this.listenTo(ResponsesStore, 'problem', () => {
			this.setState({
				isLoading: ResponsesStore.isLoading,
				isSaving: ResponsesStore.isSaving,
				error: ResponsesStore.error
			})
		});
	}

	render() {
		var {isLoading, responses, error, hasMore, hasPrev} = this.state
		return (
			this.props.children({
				isLoading,
        responses,
				error,
				hasMore,
				hasPrev
			}, {
				getResponses: AppActions.getResponses
			})
		);
	}
};

TheComponent.propTypes = {};

module.exports = TheComponent;
